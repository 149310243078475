import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import Home from "./components/Pages/Home";
import About from "./components/Pages/About";
import Services from "./components/Pages/Services";
import Contact from "./components/Pages/Contact";
import Career from "./components/Pages/Career";
import SingleServices from "./components/Pages/SingleServices";
import ScrollToTop from "./components/Layout/ScrollToTop";
import Blog from "./components/Pages/Blog";
import SingleBlog from "./components/Layout/SingleBlog";
import PageNotFound from "./components/Pages/PageNotFound";

const App = () => {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/services" element={<Services />} />
          <Route exact path="/blog" element={<Blog />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/career" element={<Career />} />
          <Route
            exact
            path="/services/:servicename"
            element={<SingleServices />}
          />
          <Route exact path="/blog/:blogName" element={<SingleBlog />} />
          <Route exact path="*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
    </HelmetProvider>
  );
};

export default App;
