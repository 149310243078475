import React from "react";
import Layout from "../Layout/Layout";
// import PageTop from "../Layout/PageTop";
import { serviceData } from "../../utils/singleServiceList";
import { useParams } from "react-router-dom";
import MFsingleService from "../Layout/MFsingleService";
import "./Singleservice.css";
import HomeContact from "../Layout/HomeContact";
import { Helmet } from "react-helmet-async";

const SingleServices = () => {
  const { servicename } = useParams();

  return (
    <Layout>
      <Helmet>
        <link
          rel="canonical"
          href={`${window.location.origin}/services/${servicename}`}
        />
        {/* <link rel="canonical" href={`${window.location.origin}/about`} /> */}
        {/* {serviceData.map(list=>(
           <meta name="description" content={list.description}/>
        ))

        } */}
       
      </Helmet>
      <div className="servicewrapper">
        {/* <PageTop PageName={"Services"}/> */}
        <div>
          {serviceData
            .filter((item) => item.urlname === servicename)
            .map((service,index) => (
              <MFsingleService
                key={index}
                image={service.img1}
                para={service.para}
                name={service.name}
                service={service.services}
                // title={service.title}
                description={service.description}
              />
            ))}
        </div>
      </div>
      <HomeContact />
    </Layout>
  );
};

export default SingleServices;
