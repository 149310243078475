import blogimg1 from "../Assets/16.jpg";
import blogimg2 from "../Assets/19.jpg"
import blogimg3 from "../Assets/26.webp"
export const blogPagelist = [
  {
    id: 1,
    blogName: "The Role of Advanced CNC Programming in Boosting India's Precision Manufacturing Industry",
    content:"India's precision manufacturing industry has entered a new era with the integration of advanced CNC programming. ",
    image: blogimg1,
  },
  {
    id: 2,
    blogName: "Top 5 Mechanical Design Trends Transforming Indian Manufacturing in 2025",
    content:"The Indian manufacturing sector is witnessing a paradigm shift, one being given by cutting-edge mechanical design trends; this is the future of the sector.",
    image: blogimg2,
  },
  {
    id: 3,
    blogName: "Jigs and Fixtures in Modern Day: On the Use of AI and IoT in Bettering Mechanical Design.",
    content:
      "The rise of jigs and fixtures has been unprecedented in the modern-day manufacturing environment, where precision and efficiency are paramount.",
    image: blogimg3,
  }
  
];
